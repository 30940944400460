import { ComponentRef } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "../../../models/shared";
import { ZxIconComponent } from "./zx-icon.component";

type stringCallback = (row: KeyMap<Base>) => string;
type booleanCallback<T> = (row: T) => boolean;

export const assignIconInputsFactory =
    <T = KeyMap<Base>>(
        iconCallback: stringCallback,
        iconSizeCallback: stringCallback,
        showCallback: booleanCallback<T>
    ) =>
    (ComponentRef: ComponentRef<ZxIconComponent>, row: T) => {
        const comp = ComponentRef.instance;
        comp.icon = iconCallback(row);
        comp.iconSize = iconSizeCallback(row);
        comp.showIcon = showCallback(row);
    };
